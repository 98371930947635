import type { ReactElement } from 'react'
// routes
import { PATH_DASHBOARD } from '@/routes/paths'
// components

import SvgIconStyle from '../../../components/SvgIconStyle'
import {
  BtIconProps,
  CreditIcon,
  CustomerIcon,
  InvoiceIcon,
  RefundIcon,
  OrderIcon,
  PromoCodeIcon,
  QuoteIcon,
  StatementIcon,
} from '@/components/icons'
import { NavConfig } from '@/components/nav-section'

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
)

type BtIcon = (props: BtIconProps) => ReactElement

const icon = (Icon: BtIcon): ReactElement => <Icon />

const ICONS = {
  // cart: getIcon('ic_cart'),
  cart: icon(OrderIcon),
  user: getIcon('ic_user'),
  customer: icon(CustomerIcon),
  banking: getIcon('ic_banking'),
  // quote: getIcon('bt_list'),
  quote: icon(QuoteIcon),
  invoice: icon(InvoiceIcon),
  // credit: getIcon('bt_coin'),
  credit: icon(CreditIcon),
  refund: icon(RefundIcon),
  statement: icon(StatementIcon),
  // promoCode: getIcon('bt_piggy-bank'),
  promoCode: icon(PromoCodeIcon),
  // invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  message: getIcon('bt_voicemail'),
  voice: getIcon('bt_mic'),
  translate: getIcon('bt_translate'),
}

const navConfig: NavConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Ecommerce',
    roles: ['admin', 'manager'],
    items: [
      {
        title: 'Order',
        path: PATH_DASHBOARD.order.list,
        icon: ICONS.cart,
      },
      {
        title: 'Invoice',
        path: PATH_DASHBOARD.invoice.list,
        icon: ICONS.invoice,
      },
      {
        title: 'Refund',
        path: PATH_DASHBOARD.refund.list,
        icon: ICONS.refund,
      },
      {
        title: 'Quote',
        path: PATH_DASHBOARD.quote.list,
        icon: ICONS.quote,
      },
      {
        title: 'Credit',
        path: PATH_DASHBOARD.credits.list,
        icon: ICONS.credit,
      },
      {
        title: 'Statement',
        path: PATH_DASHBOARD.statement.list,
        icon: ICONS.statement,
      },
      {
        title: 'promo codes',
        path: PATH_DASHBOARD.promocode.list,
        icon: ICONS.promoCode,
      },
      {
        title: 'customer',
        path: PATH_DASHBOARD.customer.list,
        icon: ICONS.customer,
      },
    ],
  },
  // PRODUCTION
  // ----------------------------------------------------------------------
  {
    subheader: 'production',
    roles: ['admin', 'manager'],
    items: [
      {
        title: 'Order',
        path: PATH_DASHBOARD.orderMessage.root,
        icon: ICONS.message,
        children: [
          { title: 'incoming', path: PATH_DASHBOARD.production.incoming },
          { title: 'waiting', path: PATH_DASHBOARD.production.waiting },
          { title: 'production', path: PATH_DASHBOARD.production.production },
          { title: 'delivered', path: PATH_DASHBOARD.production.delivered },
          { title: 'cancelled', path: PATH_DASHBOARD.production.cancelled },
        ],
      },
      {
        title: 'translation',
        path: PATH_DASHBOARD.production.translation.messages,
        icon: ICONS.translate,
      },
      {
        title: 'voice',
        path: PATH_DASHBOARD.production.voice.messages,
        icon: ICONS.voice,
      },
    ],
  },
  // PRODUCERS
  // ----------------------------------------------------------------------
  {
    subheader: 'producers',
    roles: ['admin', 'manager'],
    items: [
      {
        title: 'List',
        path: PATH_DASHBOARD.producer.list,
        icon: ICONS.user,
      },
      {
        title: 'Accounting',
        path: PATH_DASHBOARD.producer.accounting.root,
        icon: ICONS.invoice,
      },
    ],
  },
  // PRODUCERS DASHBOARD
  // ----------------------------------------------------------------------
  {
    subheader: 'producer dashboard',
    roles: ['producer'],
    items: [
      {
        title: 'Availability',
        path: PATH_DASHBOARD.producerDashboard.user.availability,
        icon: ICONS.calendar,
      },
      {
        title: 'Translation',
        path: PATH_DASHBOARD.producerDashboard.translation.messages,
        icon: ICONS.translate,
      },
      {
        title: 'Voice',
        path: PATH_DASHBOARD.producerDashboard.voice.messages,
        icon: ICONS.voice,
      },
      {
        title: 'Accounting',
        path: PATH_DASHBOARD.producerDashboard.accounting.root,
        icon: ICONS.invoice,
      },
      {
        title: 'User',
        path: PATH_DASHBOARD.producerDashboard.user.information,
        icon: ICONS.user,
      },
    ],
  },
  // ACCOUNTING
  // ----------------------------------------------------------------------
  {
    subheader: 'Accounting',
    roles: ['admin', 'accounting'],
    items: [
      // {
      //   title: 'invoice', path: PATH_DASHBOARD.invoice.root,
      //   icon: ICONS.ecommerce,
      //   children: [
      //     { title: 'list', path: PATH_DASHBOARD.invoice.list },
      //     { title: 'create', path: PATH_DASHBOARD.invoice.new },
      //   ]
      // },
      {
        title: 'Production',
        path: PATH_DASHBOARD.producer.accounting.root,
        icon: ICONS.message,
        children: [
          {
            title: 'Costs',
            path: PATH_DASHBOARD.producer.accounting.costs,
            icon: ICONS.credit,
          },
          {
            title: 'Invoice',
            path: PATH_DASHBOARD.producer.accounting.invoice,
            icon: ICONS.invoice,
          },
        ],
      },
    ],
  },
  {
    subheader: 'management',
    roles: ['admin'],
    items: [],
  },
  // {
  //   subheader: 'management',
  //   items: [
  //     // USER
  //     {
  //       title: 'user',
  //       path: PATH_DASHBOARD.user.root,
  //       icon: ICONS.user,
  //       children: [
  //         { title: 'list', path: PATH_DASHBOARD.user.list },
  //         { title: 'create', path: PATH_DASHBOARD.user.new },
  //         // { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
  //         { title: 'account', path: PATH_DASHBOARD.user.account },
  //       ],
  //     },
  //     {
  //       title: 'messages',
  //       path: PATH_DASHBOARD.general.app,
  //       icon: ICONS.dashboard,
  //       children: [
  //         { title: 'standard', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
  //         { title: 'semi-standard', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
  //         { title: 'custom', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
  //       ]
  //     },
  //     {
  //       title: 'producers',
  //       path: PATH_DASHBOARD.general.app,
  //       icon: ICONS.dashboard,
  //       children: [
  //         { title: 'voice', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
  //         { title: 'translator', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
  //       ]
  //     },
  //     {
  //       title: 'langs',
  //       path: PATH_DASHBOARD.general.app,
  //       icon: ICONS.dashboard,
  //       children: [
  //         { title: 'list', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
  //         { title: 'add', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
  //       ]
  //     }
  //   ]
  // },
  // {
  //   subheader: 'configuration',
  //   path: PATH_DASHBOARD.general.app,
  //   icon: ICONS.dashboard,
  //   items: [
  //     { title: 'site', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
  //     { title: 'translations', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard }
  //   ]
  // },
  // SEARCH SCREENS
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'Search',
  //   items: [
  //     {
  //       title: 'Messages',
  //       path: PATH_DASHBOARD.message.list,
  //       icon: ICONS.message,
  //     },
  //   ]
  // },
]

export default navConfig
