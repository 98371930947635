import { UserProfile, useUser } from '@auth0/nextjs-auth0'
// utils
import createAvatar from '@/utils/createAvatar';
//
import Avatar, { Props as AvatarProps } from './Avatar';

// ----------------------------------------------------------------------

function getUserName(user: UserProfile): string {
  return user.name ?? 'You'
}

export default function MyAvatar({ ...other }: AvatarProps) {
  const { user } = useUser();

  if (!user || !user.picture) {
    return null;
  }

  const name = getUserName(user)
  const avatar = createAvatar(name)

  return (
    <Avatar
      src={user.picture}
      alt={name}
      color={user.picture ? 'default' : avatar.color}
      {...other}
    >
      {avatar.name}
    </Avatar>
  );
}
