import * as React from 'react'
import type { SVGProps } from 'react'
const SvgChorusIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={17} height={18} viewBox="0 0 17 18" fill="none" {...props}>
    <path
      d="M3.84905 4.9254C4.96677 4.80678 6.08867 4.87472 7.20849 4.84452C7.65013 4.83266 8.04782 4.66229 8.36284 4.34958C8.63703 4.07677 8.7082 3.69613 8.70402 3.31872C8.69564 2.54127 8.6632 1.76489 8.64227 0.988513C8.64018 0.90764 8.63913 0.826767 8.64227 0.745894C8.65169 0.31026 8.92902 0.00833539 9.3466 0.00402218C10.209 -0.00352594 11.0703 0.00186558 11.9285 0.00186558C12.2445 0.176551 12.346 0.448283 12.3345 0.805201C12.3178 1.35621 12.3303 1.9083 12.3366 2.4604C12.3377 2.59195 12.3575 2.72458 12.3806 2.85505C12.4402 3.20119 12.2508 3.6282 11.8165 3.64653C11.2974 3.66809 10.7772 3.72309 10.2602 3.70691C9.49416 3.68319 9.02426 4.23636 9.02531 4.97608C9.0274 6.0932 9.03159 7.21032 9.03473 8.32744C9.03473 8.38136 9.03263 8.43527 9.03054 8.48919L8.79402 8.76415C8.64331 8.75014 8.54285 8.72965 8.44342 8.73396C7.90445 8.75337 7.35605 8.65524 6.77731 8.87414C6.658 9.0402 6.42671 9.22567 6.38694 9.4478C6.32101 9.80795 6.3545 10.1951 6.39427 10.5649C6.42671 10.8647 6.4602 11.6917 6.3859 12.0422C5.50051 12.0681 4.60571 11.9861 3.72346 12.2136C4.13476 12.2136 4.545 12.2136 4.9563 12.2136C4.90083 12.2363 4.84537 12.2783 4.78885 12.2794C4.1672 12.2848 3.5445 12.2956 2.92284 12.2827C2.44562 12.273 2.09816 12.0185 1.87629 11.5904C1.82501 11.4912 1.82605 11.3639 1.80303 11.2496V6.70352H3.47647L3.64811 6.20534L3.70043 6.21936V5.15615L3.84905 4.9254Z"
      fill="currentColor"
    />
    <path
      d="M9.04309 8.63799C9.2838 8.7965 9.54335 8.71563 9.79766 8.70053C10.1085 8.6822 10.4266 8.62721 10.7301 8.67034C11.1153 8.72426 11.3685 9.00569 11.5423 9.41976C11.5423 10.3374 11.5527 11.3068 11.536 12.2762C11.5308 12.5813 11.6061 12.8649 11.716 13.127C11.8918 13.5497 12.256 13.7502 12.6872 13.8279C13.1498 13.9109 13.6061 13.9055 14.076 13.8473C14.5532 13.789 15.0419 13.8343 15.5464 13.8343L15.8593 14.0424C15.9786 14.174 16.0194 14.3347 16.0184 14.518C16.0142 15.3936 16.0163 16.2691 16.0163 17.1415C15.8405 17.4585 15.5788 17.562 15.2314 17.5534C14.526 17.5361 13.8196 17.5512 13.1142 17.5534C12.9708 17.5534 12.8285 17.5534 12.7134 17.5534C12.481 17.3744 12.3963 17.1749 12.3931 16.9086C12.3847 16.3069 12.369 15.703 12.3188 15.1035C12.2675 14.4878 12.0582 14.2117 11.312 14.0133C10.7144 13.8548 10.0823 13.7621 9.46171 13.8451C8.75843 13.9389 8.05619 13.8785 7.355 13.9066C7.11534 13.9163 6.89975 13.8516 6.71556 13.6758C6.52299 13.4925 6.42671 13.2833 6.42566 13.0051C6.42566 12.7431 6.50625 12.467 6.3545 12.218H8.13887L8.80553 11.8222L9.04205 11.3456L9.04309 8.63799Z"
      fill="currentColor"
    />
    <path
      d="M0.47079 6.6345C0.113915 6.54716 0.0144923 6.25818 0.008213 5.94116C-0.00643876 5.09577 0.00298023 4.25038 0.00298023 3.41146C0.16415 3.07395 0.431021 2.97259 0.775337 2.98337C1.23059 2.99739 1.68689 2.98876 2.14318 2.97906C2.37552 2.97367 2.60681 2.94995 2.8381 2.92407C3.20753 2.88309 3.5351 3.10414 3.62092 3.46214C3.58324 3.93444 3.65545 4.41428 3.5801 4.87903C3.25253 4.90383 2.96682 4.9254 2.58169 4.95343L1.90771 5.3826C1.66909 5.85166 1.67537 6.23446 1.69317 6.61833L1.56235 6.63558L1.34571 6.6399L0.469743 6.63558L0.47079 6.6345Z"
      fill="currentColor"
    />
    <path
      d="M9.04309 8.63799C9.04309 9.55239 9.04205 10.4657 9.04205 11.3456L8.80553 11.8222L8.13887 12.218H6.3545L4.9563 12.2136C4.545 12.2136 4.13476 12.2136 3.72346 12.2136C4.60571 11.9861 5.50051 12.0681 6.3859 12.0422C6.4602 11.6917 6.42671 10.8647 6.39427 10.5649C6.3545 10.1951 6.32101 9.80795 6.38694 9.4478C6.42671 9.22567 6.658 9.0402 6.77731 8.87414C7.35605 8.65524 7.90445 8.75337 8.44342 8.73396C8.54285 8.72965 8.64331 8.75014 8.79402 8.76415L9.03054 8.48919L9.04309 8.63799Z"
      fill="currentColor"
    />
    <path
      d="M1.56337 6.63451L1.69419 6.61725C1.6764 6.23446 1.67012 5.85058 1.90873 5.38152L2.58271 4.95235C2.96785 4.92324 3.25355 4.90167 3.58113 4.87795C3.65648 4.4132 3.58427 3.93336 3.62194 3.46106C3.64392 3.55919 3.68264 3.65731 3.68474 3.75652C3.69206 4.12853 3.68788 4.50162 3.68788 4.88981L3.84905 4.9254L3.70043 5.15615V6.21936L3.64811 6.20534L3.47647 6.70352H1.80303V11.2496C1.77896 11.1796 1.73605 11.1084 1.735 11.0383C1.72768 10.0312 1.72663 9.02403 1.72454 8.01689C1.72663 7.65027 1.73082 7.28257 1.72663 6.91594C1.72663 6.84693 1.68582 6.779 1.66384 6.70999L1.56337 6.63451Z"
      fill="currentColor"
    />
    <path
      d="M1.66384 6.70999C1.68582 6.779 1.72663 6.84693 1.72663 6.91594C1.73082 7.28257 1.72663 7.65027 1.72454 8.01689C1.70256 7.94788 1.6607 7.87887 1.65965 7.80878C1.65547 7.44215 1.66175 7.07661 1.66384 6.70999Z"
      fill="currentColor"
    />
    <path
      d="M0.47079 6.6345L1.34673 6.63882C1.0673 6.73048 0.730335 6.72832 0.47079 6.6345Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgChorusIcon
