import { useSession } from '@/contexts/SessionContext'
import { Stack, InputLabel } from '@mui/material'
import { Site } from '@slc/types'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { Globe } from 'react-bootstrap-icons'
import { BTIcon } from './Iconify'
import Selector, { Option, OptionValue } from './inputs/Selector'
import { useCurrentRouterLocale } from '@slc/hooks'

const countrySelections: Option[] = [
  { label: 'United Kingdom', value: 'uk' },
  { label: 'France', value: 'fr' },
  { label: 'Spain', value: 'es' },
]

export const CountrySelector = () => {
  const router = useRouter()
  const { isLoading, error, session } = useSession()

  const countryCode = useCurrentRouterLocale()
  const [selection, setSelection] = useState<string>(countryCode as string)

  useEffect(() => {
    if (countryCode !== selection) {
      router.push({ pathname: router.pathname }, router.asPath, { locale: selection })
    }
  }, [countryCode, selection, setSelection, router])

  if (isLoading || error || !session) {
    return null
  }

  const sites = session?.allowedSites

  return !sites || sites.length < 2 ? null : (
    <>
      <Stack direction="row" alignItems="center">
        <BTIcon Icon={Globe} sx={{ paddingRight: '.5rem' }} />
        <InputLabel>Country</InputLabel>
      </Stack>
      <Selector
        options={countrySelections.filter(({ value }) => sites.includes(value as unknown as Site))}
        value={selection}
        onChange={(value?: OptionValue) => {
          if (value) {
            setSelection(value as string)
          }
        }}
      />
    </>
  )
}
