import { ReactNode } from 'react';
// next
import { useRouter } from 'next/router';
// hooks
import { useUser } from '@auth0/nextjs-auth0'
// components
import LoadingScreen from '../components/LoadingScreen';

import { PATH_AUTH } from '@/routes/paths';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

export default function AuthGuard({ children }: Props) {
  const { isLoading, error } = useUser();

  const router = useRouter();

  // useEffect(() => {
  //   if (error) {
  //     router.replace(PATH_AUTH.login)
  //   }
  // }, [router, error])
  if (error) {
    router.replace(PATH_AUTH.login)
  }

  if (isLoading) {
    return <LoadingScreen />
  }

  return <>{children}</>;
}
