import isString from 'lodash/isString'
import defaults from 'lodash/defaults'
import { ReactElement } from 'react'
import {
  type IconProps,
  CartFill,
  X,
  Icon,
  PersonRolodex,
  PlayBtn,
  PauseBtn,
  Download,
  Upload,
  InfoCircle,
  Globe,
  Mic,
  EnvelopePaper,
  Star,
  StarFill,
  Pencil,
  CheckLg,
  Send,
  HourglassTop,
  HourglassBottom,
  Eyeglasses,
  ArrowRepeat,
  Trash,
  Plus,
  CalendarX,
  ArrowLeftShort,
  ArrowRightShort,
  // payment type
  CreditCard,
  CreditCard2FrontFill,
  CardText,
  // CashStack,
  CurrencyExchange,
  ArrowCounterclockwise,
  Bank2,
  // customer type
  PersonLinesFill,
  PersonBoundingBox,
  PersonWorkspace,
  Person,

  // order type
  Cart3,
  Coin,
  PhoneVibrate,
  Sliders2Vertical,
  Globe2,
  ListColumnsReverse,

  // Payment
  PiggyBankFill,

  // Entities
  CartCheckFill,
  LayoutTextSidebarReverse,
  Tag,
  Table,

  // order production status
  CreditCardFill,
  ConeStriped,
  RecordCircle,
  Truck,
  XLg,

  // discount type
  Percent,
  CashCoin,

  // promocode type
  Calendar,
  Stopwatch,
  Calendar2Event,

  // document type
  Cash,
  FileEarmarkRuled,
  FileEarmarkExcel,
  JournalText,
  JournalX,

  // credit statement type
  ArrowDownCircle,

  // contact type
  Telephone,
  Envelope,

  //
  FileZip,
  Paypal,
  SendXFill,
} from 'react-bootstrap-icons'
import SvgChorusIcon from '@/assets/icons/ChorusIcon'

import palette from '@/theme/palette'

const SMALL_SIZE = 'small'
const NORMAL_SIZE = 'normal'
const LARGE_SIZE = 'large'

const sizes = [SMALL_SIZE, NORMAL_SIZE, LARGE_SIZE] as const

type BtIconSize = typeof sizes[number]

export type BtIconProps = IconProps & {
  size?: BtIconSize | number
}

const iconSize = {
  [SMALL_SIZE]: 14,
  [NORMAL_SIZE]: 20,
  [LARGE_SIZE]: 36,
}
function withDefaults(BtIcon: Icon, defaultProps?: BtIconProps) {
  return function (props: BtIconProps): ReactElement {
    const size: number | undefined = isString(props.size) ? iconSize[props.size] : props.size

    return (
      <BtIcon
        {...Object.assign(
          defaults(defaultProps, { size: 20, color: 'currentColor' }, props),
          size ? { size } : {}
        )}
      />
    )
  }
}

export type IconConstructor = (props: BtIconProps) => ReactElement

export const UserIcon = withDefaults(Person)
export const ProducerIcon = withDefaults(PersonRolodex)
export const CartIcon = withDefaults(CartFill)
export const ManualOnIcon = withDefaults(Star, { color: 'orange' })
export const ManualOffIcon = withDefaults(StarFill, { color: 'orange', stroke: 'orange' })
export const CloseIcon = withDefaults(X)
export const PlayIcon = withDefaults(PlayBtn)
export const PauseIcon = withDefaults(PauseBtn)
export const DownloadIcon = withDefaults(Download)
export const UploadIcon = withDefaults(Upload)
export const InfoIcon = withDefaults(InfoCircle)
export const VoiceIcon = withDefaults(Mic)
export const TranslateIcon = withDefaults(Globe)
export const EditIcon = withDefaults(Pencil)
export const TickIcon = withDefaults(CheckLg)
export const SendIcon = withDefaults(Send)
export const AddIcon = withDefaults(Plus)
export const DeleteIcon = withDefaults(Trash)

export const ProductionTranslationIcon = withDefaults(Globe)
export const ProductionWaitingIcon = withDefaults(HourglassTop)
export const ProductionWaitingOverdueIcon = withDefaults(HourglassBottom)
export const ProductionReviewIcon = withDefaults(Eyeglasses)
export const ProductionRetakeIcon = withDefaults(ArrowRepeat)
export const ProductionCompleteIcon = withDefaults(CheckLg)

export const AvailableIcon = withDefaults(CheckLg)
export const UnavailableIcon = withDefaults(X)
export const AvailabilityIcon = withDefaults(CalendarX)

export const MailProducerIcon = withDefaults(EnvelopePaper)

export const PreviousIcon = withDefaults(ArrowLeftShort)
export const NextIcon = withDefaults(ArrowRightShort)

export const PaymentCardIcon = withDefaults(CreditCard)
export const PaymentCheckIcon = withDefaults(CardText)
export const PaymentTransferIcon = withDefaults(Bank2)
export const PaymentCredit2Icon = withDefaults(CreditCard2FrontFill)
export const PaymentCreditIcon = withDefaults(Coin)
export const PaymentDebitIcon = withDefaults(CurrencyExchange)
// export const PaymentRefundIcon = withDefaults(CurrencyExchange)
export const PaymentRefundIcon = withDefaults(ArrowCounterclockwise)

export const CustomerIcon = withDefaults(PersonLinesFill)
export const PremiumIcon = withDefaults(PersonBoundingBox)
export const ResellerIcon = withDefaults(PersonWorkspace)

export const OrderFunnelIcon = withDefaults(Cart3)
export const OrderCreditIcon = withDefaults(Coin)
export const OrderGsmIcon = withDefaults(PhoneVibrate)
export const OrderDnsIcon = withDefaults(Globe2)
export const OrderMixingIcon = withDefaults(Sliders2Vertical)
export const OrderOtherIcon = withDefaults(ListColumnsReverse)

export const WaitingPaymentOrderIcon = withDefaults(CreditCardFill, {
  color: palette.dark.warning.main,
})
export const UnpaidOrderIcon = withDefaults(PiggyBankFill, { color: palette.dark.warning.main })
export const PaymentIssueOrderIcon = withDefaults(CreditCardFill, {
  color: palette.dark.error.main,
})
export const OverdueOrderIcon = withDefaults(PiggyBankFill, { color: palette.dark.error.main })
export const PaidOrderIcon = withDefaults(PiggyBankFill, { color: palette.dark.success.main })

export const OrderIcon = withDefaults(CartCheckFill)
export const QuoteIcon = withDefaults(LayoutTextSidebarReverse)
export const CreditIcon = withDefaults(Coin)
export const InvoiceIcon = withDefaults(JournalText)
export const RefundIcon = withDefaults(ArrowCounterclockwise)
export const StatementIcon = withDefaults(FileEarmarkRuled)
export const PromoCodeIcon = withDefaults(Tag)
export const PaypalIcon = withDefaults(Paypal)
export const ChorusIcon = withDefaults(SvgChorusIcon)

// order production status
export const IncomingProductionIcon = withDefaults(CreditCardFill, {
  color: palette.light.error.main,
})
export const WaitingProductionIcon = withDefaults(ConeStriped, {
  color: palette.light.warning.main,
})
export const ProductionProductionIcon = withDefaults(RecordCircle, {
  color: palette.light.info.main,
})
export const ProductionDeliveredIcon = withDefaults(Truck, { color: palette.light.success.main })
export const ProductionCancelledIcon = withDefaults(XLg, { color: palette.light.grey[500] })

export const PercentDiscountIcon = withDefaults(Percent)
export const AmountDiscountIcon = withDefaults(CashCoin)
export const PermanentPromocodeIcon = withDefaults(Calendar)
export const CodePromocodeIcon = withDefaults(Stopwatch)

export const PromocodeLastUsedIcon = withDefaults(Calendar2Event)

export const InvoiceDocumentIcon = withDefaults(JournalText)
export const RefundInvoiceDocumentIcon = withDefaults(JournalX)
export const StatementDocumentIcon = withDefaults(FileEarmarkRuled)
export const RefundStatementDocumentIcon = withDefaults(FileEarmarkExcel)
export const RefundDocumentIcon = withDefaults(Cash)

export const TopupCreditStatementTypeIcon = withDefaults(ArrowDownCircle)
export const OrderCreditStatementTypeIcon = withDefaults(CartCheckFill)
export const RefundCreditStatementTypeIcon = withDefaults(ArrowCounterclockwise)
export const AddCreditStatementTypeIcon = withDefaults(CurrencyExchange)
export const RemoveCreditStatementTypeIcon = withDefaults(CurrencyExchange)

export const TelephoneIcon = withDefaults(Telephone)
export const MailIcon = withDefaults(Envelope)
export const ZipIcon = withDefaults(FileZip)
