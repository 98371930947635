import { memo } from 'react';
// @mui
import { Stack } from '@mui/material';
//
import { NavSectionProps } from '../type';
import NavList from './NavList';
import RoleBasedGuard from '@/guards/RoleBasedGuard';

// ----------------------------------------------------------------------

const hideScrollbar = {
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
} as const;

function NavSectionHorizontal({ navConfig }: NavSectionProps) {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      sx={{ bgcolor: 'background.neutral', borderRadius: 1, px: 0.5 }}
    >
      <Stack direction="row" sx={{ ...hideScrollbar, py: 1 }}>
        {navConfig.map((group, index) => (
          <RoleBasedGuard roles={group.roles} key={`nav-horiz-${index}`}>
            <Stack key={group.subheader} direction="row" flexShrink={0}>
              {group.items.map((list) => (
                <NavList
                  key={list.title + list.path}
                  data={list}
                  depth={1}
                  hasChildren={!!list.children}
                />
              ))}
            </Stack>
          </RoleBasedGuard>
        ))}
      </Stack>
    </Stack>
  );
}

export default memo(NavSectionHorizontal);
