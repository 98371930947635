// @mui
import { List, Box, useTheme } from '@mui/material';
// hooks
import useLocales from '@/hooks/useLocales';
//
import { NavSectionProps } from '../type';
import { ListSubheaderStyle } from './style';
import NavList from './NavList';
import { CountrySelector } from '@/components/CountrySelector';
import RoleBasedGuard from '@/guards/RoleBasedGuard';

// ----------------------------------------------------------------------

export default function NavSectionVertical({ navConfig, isCollapse, ...other }: NavSectionProps) {
  const { translate } = useLocales();
  const theme = useTheme()
  return (
    <Box {...other}>
      <Box sx={{ padding: theme.spacing(2) }}>
        <CountrySelector />
      </Box>

      {navConfig.map((group, index) => (
        <RoleBasedGuard roles={group.roles} key={`nav-group-${index}`}>
          <List key={group.subheader} disablePadding sx={{ px: 2 }}>
            <ListSubheaderStyle
              disableGutters
              sx={{
                ...(isCollapse && {
                  opacity: 0,
                }),
              }}
            >
              {translate(group.subheader)}
            </ListSubheaderStyle>
            {group.items.map((list) => (
              <NavList
                key={list.title + list.path}
                data={list}
                depth={1}
                hasChildren={!!list.children}
                isCollapse={isCollapse}
              />
            ))}
          </List>
        </RoleBasedGuard>
      ))}
    </Box>
  );
}
